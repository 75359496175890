<!--在线预约-->
<template>
  <div class="giEdificeDiv">
    <el-row class="outerRow">
      <el-col >
        <top-path-list :path-obj="pathObj"></top-path-list>
      </el-col>
    </el-row>
    <el-row class="outerRow">
      <el-col >
        <el-row class="contentDiv">
          <el-col :span="12" :offset="6">
            <el-form ref="form" :model="form" label-width="100px" :rules="rules">
              <el-row class="formRowClass">
                <el-form-item prop="name" label="姓名">
                  <el-input v-model="form.name" placeholder="请输入"></el-input>
                </el-form-item>
              </el-row>
              <el-row class="formRowClass">
                <el-form-item prop="telephone" label="手机号" class="imgFormItem">
                  <el-input v-model="form.telephone" placeholder="请输入"></el-input>
                  <el-button class="switchImg" :disabled="disabled" @click="getKaptcha">{{codeTitle}}</el-button>
                </el-form-item>
              </el-row>
              <el-row class="formRowClass">
                <el-form-item prop="smsCode" label="验证码">
                  <el-input type="text" v-model="form.smsCode" placeholder="请输入">
                  </el-input>
                </el-form-item>
              </el-row>
              <el-row class="formRowClass">
                <el-form-item prop="describ" label="预约描述">
                  <el-input type="textarea" :rows="6" placeholder="请输入" v-model="form.describ">
                  </el-input>
                </el-form-item>
              </el-row>
            </el-form>
            <div style="text-align: center">
              <el-button class="btnClass" type="primary" @click="onSubmit" >预约</el-button>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import topPathList from '@/components/topPathList'
import axios from 'axios'
import { echoImg } from '@/utils/commonApi'

export default {
  name: 'online',
  components: { topPathList },
  data() {
    return {
      pathObj: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '地标大厦',
          path: '/giEdifice/index'
        },
        {
          name: '在线预约',
          path: '/giEdifice/online'
        },
      ],
      form: {
        name: '',
        telephone: '',
        smsCode: '',
        describ: '',
        reservationTime: '',
      },
      rules: {
        name: [{ required: true, message: '请输入', trigger: 'blur' }],
        telephone: [{ required: true, message: '请输入', trigger: 'blur' },
          { pattern:/^1([38][0-9]|4[014-9]|[59][0-35-9]|6[2567]|7[0-8])\d{8}$/,message: '请输入正确的手机号码', trigger: 'blur' },],
        describ: [{ required: true, message: '请输入', trigger: 'blur' }],
        smsCode: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
      timer: null, //计时器
      disabled: false, //是否禁用按钮
      second: '', //倒计时
    }
  },
  //计算属性
  computed: {
    codeTitle() {
      return this.disabled ? `${this.second}秒后重发` : '获取验证码';
    },
  },
  mounted () {
    this.getNowTime();
  },
  beforeDestroy () {
    clearInterval(this.timer)
  },
  methods: {// 时间补0
    checkTime(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
    getNowTime(){
      let myDate = new Date();	//创建Date对象
      let Y = myDate.getFullYear();   //获取当前完整年份
      let M = this.checkTime(myDate.getMonth() + 1);  //获取当前月份
      let D = this.checkTime(myDate.getDate());   //获取当前日1-31

      // 拼接日期分隔符根据自己的需要来修改
      this.form.reservationTime = Y + '-' + M + '-' + D;
    },
    getKaptcha() {
      //没有输入手机号就点击验证码按钮
      if (this.form.telephone == "") {
        this.$message.warning("请输入手机号")
      } else { //调用获取验证码按钮

        //倒计时
        let that = this
        let s = 300  //倒计时间
        if (!that.timer) {
          that.second = s
          that.disabled = true
          that.timer = setInterval(() => {
            if (that.second > 0 && this.second <= s) {
              that.second--
            } else {
              that.disabled = false
              clearInterval(that.timer)
              this.timer = null
            }
          }, 1000)
        }

        let params = {
          phoneNumber: that.form.telephone,
        }
        axios({
          method: "post",
          url: process.env.VUE_APP_HUSSAR_DEFAULT_API + '/api/portal/user/sendSms?phoneNumber=' + that.form.telephone,
          data: params
        }).then(res => {
          if (res.data.code == 200 || res.data.code == 10000) {

          } else {
            that.$message.warning(res.data.msg)
          }
        }).catch(err => {
          that.$message.error('请求失败')
        })
      }
    },
    onSubmit() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.$refs.form.validate((valid) => {
        if (valid) {
          axios({
            method: "post",
            url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/edifice/onlinereservation/insertOrUpdate",
            data: this.form,
          }).then(res => {
            if (res.data.code === 10000 || res.data.code === 200) {
              this.$message.success('预约成功，3秒后退出当前页面')
              loading.close();
              // 预约成功后 3s 返回大厦首页
              setTimeout(() => {
                this.$router.push({
                  path: '/giEdifice/index',
                })
              }, 3000)
            } else {
              loading.close();
              this.$message.error(res.data.msg)
            }
          }).catch(err => {
            loading.close();
            this.$message.error('接口调用异常')
          })
        } else {
          loading.close();
        }
      })

    },
  }
}
</script>

<style scoped lang="less">
@import '~@/assets/css/common.less';
.giEdificeDiv {
  background-color: #f8f9fa !important;
}
.contentDiv {
  min-height: 517px;
  background: white;
  margin-bottom: 40px;
  padding: 25px;
}
.btnClass {
  width: 20%;
  background: linear-gradient(90deg, #22C5FF 0%, #186CF5 100%);
  border-radius: 5px;
}
.switchImg {
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: bold;
  color: #186CF5;
  width: 100px;
  margin-left: 5px;
  border: solid 1px #186CF5;
  background: #EEF4FF;
  padding: 12px;
}
::v-deep .imgFormItem .el-form-item__content {
  display: flex;
  align-items: center;
}
.el-form-item__content {
  display: flex;
}
</style>
